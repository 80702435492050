/*
 * @Descripttion: Banner接口
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-10-09 09:47:43
 * @LastEditTime: 2021-10-09 10:08:34
 */
const url = 'index/Banner/'
import {
    $get
} from "@/utils"


/**
 * 出售中的游戏Banner列表
 * @param {array} params 请求数据
 */
 export function getBannerList(params) {
   return $get(url + 'list', params)
}
