<!--
 * @Descripttion: 平台首页
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-12 01:52:15
 * @LastEditTime: 2021-11-19 02:20:25
-->
<template>

  <div id="areaDiv"
       class="flex"
       v-wechat-title="'犀利装备交易平台-犀利担保-犀利交易-犀利龙之谷-QQ龙交易平台'">
    <div class="top_banner">

      <div class="aboutus_div">

        <div class="aboutus_title">
          <p class="aboutus_title_p"><span>关于我们</span></p>
          <p style="margin: 0 0 0 10px;">About US</p>
        </div>
        <div class="aboutus_content">
          <p>如果有号却不知道行情！</p>
          <p>如果看中号，钱却不够！</p>
          <p>如急用钱，但号无法出售！</p>
          <p>如果想交易，但怕被骗！</p>
          <p>联系我们，我们为此而在！</p>
        </div>
      </div>
      <div class="banner"
           :style="''">

        <swiper :options="swiperOption"
                ref="mySwiper"
                style="height:100%">
          <!-- slides -->
          <swiper-slide v-for="(item,index) in BannerList"
                        :key="index">
            <img style="width: 100%;min-height:100%"
                 :src="item.img" />

          </swiper-slide>

          <!-- Optional controls -->
          <div class="swiper-pagination"
               slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
    <div class="swiper-scrollbar"   slot="scrollbar"></div> -->
        </swiper>
      </div>
      <div class="addQ ">
        <div class="addQ_div">
          犀利账号装备Q群
        </div>
        <div style="display: flex; align-items: center;">
          <hr />欢迎点击加入
          <hr />
        </div>
        <a href="https://jq.qq.com/?_wv=1027&k=o4SLp3tK"
           target="_blank"
           style="text-decoration: none;">
          <div class="areaDiv_div addQun">
            <div> 犀利1群(满3千人) </div>
          </div>
        </a>
        <a href="https://jq.qq.com/?_wv=1027&k=VzOwn78F"
           target="_blank"
           style="text-decoration: none;">
          <div class="areaDiv_div addQun">
            <div> 犀利2群(满3千人) </div>
          </div>
        </a>
        <a href="https://jq.qq.com/?_wv=1027&k=pbJKaoKr"
           target="_blank"
           style="text-decoration: none;">
          <div class="areaDiv_div addQun">
            <div> 犀利3群(还有位置) </div>
          </div>
        </a>
        <a href="https://jq.qq.com/?_wv=1027&k=YgLQPNco"
           target="_blank"
           style="text-decoration: none;">
          <div class="areaDiv_div addQun">
            <div> 犀利4群(空闲) </div>
          </div>
        </a>

      </div>
    </div>
    <div class="areaDiv_div"
         v-for="(item,index) in CategoryList"
         :key="index">
      <div @click="go(item.category_id+'')">
        {{item.category_name}}
      </div>
    </div>
    <div class="areaDiv_div"
         style="background:#ee8500;"
         @click="goto('/SoldOut')">
      <div style="border: 0px solid #ee8500;box-shadow: 0px 4px 12px #ee8500; color:white">
        已出售帐号
      </div>
    </div>
    <div class="areaDiv_div"
         @click="goPay()"
         style="background:#01a9ab;">
      <div style="border: 0px solid #01a9ab;box-shadow: 0px 4px 12px #01a9ab; color:white">
        我要卖
      </div>
    </div>
    <div class="areaDiv_div"
         @click="goto('/Equip')"
         style="background:#f2a289;">
      <div style="border: 0px solid #f2a289;box-shadow: 0px 4px 12px #f2a289; color:white">
        在售装备
      </div>
    </div>
    <div class="addQ addQQ">
      <div class="addQ_div">
        犀利账号装备Q群
      </div>
      <div style="display: flex; align-items: center;">
        <hr />欢迎点击加入
        <hr />
      </div>
      <a href="https://jq.qq.com/?_wv=1027&k=o4SLp3tK"
         target="_blank"
         style="text-decoration: none;">
        <div class="areaDiv_div addQun">
          <div> 犀利1群(满3千人) </div>
        </div>
      </a>
      <a href="https://jq.qq.com/?_wv=1027&k=VzOwn78F"
         target="_blank"
         style="text-decoration: none;">
        <div class="areaDiv_div addQun">
          <div> 犀利2群(满3千人) </div>
        </div>
      </a>
      <a href="https://jq.qq.com/?_wv=1027&k=pbJKaoKr"
         target="_blank"
         style="text-decoration: none;">
        <div class="areaDiv_div addQun">
          <div> 犀利3群(还有位置) </div>
        </div>
      </a>
      <a href="https://jq.qq.com/?_wv=1027&k=YgLQPNco"
         target="_blank"
         style="text-decoration: none;">
        <div class="areaDiv_div addQun">
          <div> 犀利4群(空闲) </div>
        </div>
      </a>

    </div>
    <el-dialog class="dialog"
               :visible.sync="isPAY"
               :modal-append-to-body=false
               top="25%"
               width="250px">

      <!-- goto('member/person/MemberGameRole') -->
      <div style="text-align: center;">
        <div style="    font-size: 20px;
    margin-top: -30px;
    padding-bottom: 15px; 
}">
          我要卖
        </div>
        <div style="margin-bottom: 10px;">
          <el-button @click="goto('member/person/MemberGameRole')" type="primary">我要卖账号</el-button>
        </div>
        <div>
          <el-button @click="goto('member/person/MemberEquip')" type="success">我要卖装备</el-button>
        </div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
import { getCateGory } from "@/api";
import { getBannerList } from "@/api/banner";
import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "DbgamewebIndex",
  props: ["bannerWidth"],
  components: {
    swiper,
    swiperSlide,
  },

  //directives: { DirectiveName },

  data() {
    return {
      isPAY: false,
      publicPath: process.env.BASE_URL,
      CategoryList: [],
      BannerList: [],
      swiperOption: {
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...

        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          dynamicBullets: false,
        },
        speed: 4000,
        effect: "fade",
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,

          waitForTransition: true, //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时。
        },
        // navigation:{
        //   nextEl:'.swiper-button-next',
        //   prevEl:'.swiper-button-prev',
        // }
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  watch: {},
  mounted() {
    // console.log('this is current swiper instance object', this.swiper)
    //  this.swiper.slideTo(3, 1000, false)
    //console.log(1,this.bannerWidth);
    this.getCateGory();
    this.getBannerList();
  },

  methods: {
    goPay() {
      this.isPAY = true;
    },
    getBannerList() {
      getBannerList("").then((res) => {
        this.BannerList = res.data.list;
      });
    },
    go(cid) {
      this.$router.push("/GameRoleList/" + cid);
    },
    goto(url) {
      this.$router.push(url);
    },
    getCateGory() {
      let params = {
        pid: 23,
        sort_field: "category_id",
        sort_type: "asc",
      };
      //23是游戏分区的ID
      getCateGory(params).then((res) => {
        this.CategoryList = res.data.clist;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.addQQ {
  display: none;
}
.aboutus_div {
  width: 215px;
  color: #ffffff;
  float: left;
  .aboutus_title {
    color: #fff;
    border: 5px rgb(1, 169, 171) solid;
    border-bottom: 0;
    border-right: 0;
    border-top: 0;
    margin: 10px;

    .aboutus_title_p {
      font-size: 20px;
      margin: 0 0 0 10px;
      font-weight: bold;
      color: rgb(1, 169, 171);
    }
  }
  .aboutus_content {
    padding: 10px;
    font-size: 15px;
    p {
      font-size: 16px;
    }
  }
}
.addQ {
  width: 280px;
  float: left;
  color: #ffffff;
  text-align: center;
  .addQ_div {
    color: rgb(1, 169, 171);
    margin: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  hr {
    width: 20%;
    border: none;
    border-top: 1px solid #555;
    display: block;
    unicode-bidi: -webkit-isolate;
    unicode-bidi: -moz-isolate;
    unicode-bidi: isolate;
    -webkit-margin-before: 0.5em;
    margin-block-start: 0.5em;
    -webkit-margin-after: 0.5em;
    margin-block-end: 0.5em;
    -webkit-margin-start: auto;
    margin-inline-start: auto;
    -webkit-margin-end: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
  }
  .addQun {
    width: 80%;
    height: 3rem;
    line-height: 3rem;
    margin: 0 10%;
    background: black;
    margin-top: 2px;
    margin-bottom: 8px;
  }
  span {
    font-size: 14px;
  }
}
</style>
